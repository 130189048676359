import React from 'react';

import { LinkButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import { ContextualAnalyticsData, FireScreenAnalytics, SCREEN } from '@atlassian/analytics-bridge';

/**
 * __Content rendering error__
 *
 * A content rendering error displays a generic error message with a link to `/`.
 */
const ContentRenderingError = () => {
	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="contentError">
			<FireScreenAnalytics />
			<EmptyState
				header="Something unexpected happened"
				description="There was a problem rendering this content, our team has been notified."
				primaryAction={<LinkButton href="/">Head home</LinkButton>}
			/>
		</ContextualAnalyticsData>
	);
};

export default ContentRenderingError;
