/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { Fragment, type ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { HorizontalScrollContainer, SectionLink } from '@af/design-system-docs-ui';
import Link from '@atlaskit/link';
import { Code, CodeBlock, type SupportedLanguages } from '@atlaskit/code';
import EmptyState from '@atlaskit/empty-state';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import ErrorBoundary from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/error-boundary';
import { ContentRenderingError, DoDont, VideoEmbed } from '@af/design-system-docs-ui';
import resolveLocalisation from '../utils/resolveLocalisation';
import { buildUrl } from '../utils/buildUrl';
import AssetCard from './content-types/asset-card';
import ColorCard from './content-types/color-card';
import ThemedImg from './themed-img';
import MDXFile from './content-types/mdx-file';

import { addHeadingId, getHeadingId } from '../utils/heading-id';

/**
 * Renders content for guideline pages (from Contentful).
 */
const options: Parameters<typeof renderRichText>[1] = {
	renderMark: {
		[MARKS.CODE]: (text: ReactNode) => <Code>{text}</Code>,
	},
	renderNode: {
		// An entry-hyperlink is when one Contentful entry links to another. We handle
		// this explicitly so that we can build the right URL and use an SPA router Link
		[INLINES.ENTRY_HYPERLINK]: (node, children) => {
			const { slug, category, contentfulparent } = node.data.target;
			const parentSlug = resolveLocalisation(contentfulparent)?.slug;
			const link = buildUrl([category, parentSlug, slug]);
			return <Link href={link}>{children}</Link>;
		},
		[INLINES.ASSET_HYPERLINK]: (node, children) => {
			return <Link href={node.data.uri}>{children}</Link>;
		},
		[INLINES.HYPERLINK]: (node, children) => {
			return <Link href={node.data.uri}>{children}</Link>;
		},
		[BLOCKS.PARAGRAPH]: (_node, children) => {
			// Don't render an empty paragraph
			if (!Array.isArray(children) || children.every((item) => item === '')) {
				return children;
			}

			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			return <p style={{ whiteSpace: 'pre-wrap' }}>{children}</p>;
		},
		[BLOCKS.HEADING_2]: (node: any, children) => {
			return (
				<SectionLink id={getHeadingId(node)} depth={2}>
					{children}
				</SectionLink>
			);
		},
		[BLOCKS.HEADING_3]: (node: any, children) => {
			return (
				<SectionLink id={getHeadingId(node)} depth={3}>
					{children}
				</SectionLink>
			);
		},
		[BLOCKS.EMBEDDED_ASSET]: (node) => {
			const target = resolveLocalisation(node.data.target);
			if (!target) {
				return <EmptyState header="Something went wrong" />;
			}
			return (
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				<img css={{ maxWidth: '100%' }} alt={target.description || ''} src={target.file.url} />
			);
		},
		// Embedded entries are basically the whole reason we use Contentful's Rich
		// Text features. This is how we create custom content types like DosAndDonts
		// or ColorCards and then embed them inside posts in the CMS.
		[BLOCKS.EMBEDDED_ENTRY]: (node: any, children) => {
			const contentType = node.data.target?.__typename;
			switch (contentType) {
				case 'ContentfulDosAndDonts':
					const { text, type, fullWidth, image } = node.data.target;
					return (
						<DoDont
							type={type === 'Do' ? 'do' : 'dont'}
							image={image && { alt: image.description, url: image.file.url }}
							isGridLayout={!fullWidth}
						>
							{resolveLocalisation(text)}
						</DoDont>
					);
				case 'ContentfulMarkdown':
					// ContentfulMarkdown entries can be used to inject Markdown into
					// a Rich Text entry. This primarily exists to support tables.
					const markdown = node.data.target?.childContentfulMarkdownTextTextNode?.text;
					return (
						<ReactMarkdown
							remarkPlugins={[remarkGfm]}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="brisk-table"
							children={markdown}
							components={{
								table({
									children,
									className,
								}: {
									inline?: boolean;
									children: ReactNode[];
									className?: string;
								}) {
									return (
										/**
										 * Tables are an example of content that may need to scroll horizontally.
										 * To enable a horizontally scrollable table to be navigated with the keyboard,
										 * we need to add a tabIndex to the table element and add a shadow "hint".
										 */
										<HorizontalScrollContainer>
											{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
											<table className={className}>{children}</table>
										</HorizontalScrollContainer>
									);
								},
								code({
									children,
									className,
									/** prop exposed in react markdown */
									inline,
								}: {
									inline?: boolean;
									children: ReactNode[];
									className?: string;
								}) {
									if (inline) {
										return <Code>{children}</Code>;
									}

									const language = /language-(\w+)/.exec(
										className || '',
									)?.[1] as SupportedLanguages;
									const value = String(children);

									return <CodeBlock text={value.trimEnd()} language={language || undefined} />;
								},
							}}
						/>
					);
				case 'ContentfulAssetCard': {
					const { asset, title, thumbnail, thumbnailDark } = resolveLocalisation(node.data.target);
					const assetFields = resolveLocalisation(asset);
					const assetFileFields = resolveLocalisation(assetFields.file);
					const thumbnailLightFields = resolveLocalisation(thumbnail);
					const thumbnailDarkFields = resolveLocalisation(thumbnailDark);
					const thumbnailLightUrl =
						thumbnailLightFields && resolveLocalisation(thumbnailLightFields.file).url;
					const thumbnailDarkUrl =
						thumbnailDarkFields && resolveLocalisation(thumbnailDarkFields.file).url;

					return (
						<AssetCard
							title={resolveLocalisation(title)}
							link={assetFileFields.url}
							fileSize={assetFileFields.details.size}
							thumbnail={
								thumbnailLightUrl && {
									light: thumbnailLightUrl,
									// Dark thumbnail defaults to light if not set
									dark: thumbnailDarkUrl || thumbnailLightUrl,
								}
							}
						/>
					);
				}
				case 'ContentfulAssetsContainer': {
					// An AssetsContainer is basically just a wrapper around multiple AssetsCards
					// to group things in the CMS. An AssetsContainer contains rich text itself,
					// and we could pass the data into another renderRichText() call, but in this
					// case it is simpler to just map over the references as we know they'll be
					// AssetCards.
					const references = node.data.target?.contentfulchildren?.references;
					const thumbnailLightUrl = node?.thumbnail?.file?.url;
					const thumbnailDarkUrl = node?.thumbnail?.file?.url;

					return (
						<Fragment>
							{references.map((node: any) => {
								return (
									<AssetCard
										key={node?.asset?.id}
										title={node?.asset?.title}
										link={node?.asset?.file?.url}
										fileSize={node?.asset?.file?.details.size}
										thumbnail={
											thumbnailLightUrl && {
												light: thumbnailLightUrl,
												// Dark thumbnail defaults to light if not set
												dark: thumbnailDarkUrl || thumbnailLightUrl,
											}
										}
									/>
								);
							})}
						</Fragment>
					);
				}
				case 'ContentfulColorCard': {
					const target = node.data.target;
					const color = resolveLocalisation(target?.hexCode);
					const name = resolveLocalisation(target?.name);
					return <ColorCard hexColor={color} name={name} />;
				}
				case 'ContentfulSectionMessage': {
					const target = node.data.target;

					const actions = ['action1', 'action2'].reduce((prev, current) => {
						if (target[current]) {
							prev.push(
								// @ts-expect-error
								<SectionMessageAction href={target[current].link} key={target[current].text}>
									{target[current].text}
								</SectionMessageAction>,
							);
						}
						return prev;
					}, []);

					return (
						<SectionMessage
							title={target?.title}
							appearance={target?.appearance}
							actions={actions.length ? actions : undefined}
						>
							{target?.message?.raw && <ContentfulRenderer richText={target?.message} />}
						</SectionMessage>
					);
				}

				case 'ContentfulMdxFile': {
					const target = node.data.target;
					return <MDXFile fileName={target?.fileName} />;
				}
				case 'ContentfulThemedImage': {
					const target = node.data.target;

					return (
						<ThemedImg
							src={{
								light: target?.light.file.url,
								dark: target?.dark.file.url,
							}}
							// Assumes light and dark have the same dimensions
							height={target?.light.file.details.image.height}
							width={target?.light.file.details.image.width}
							alt={target?.light.description || ''}
						/>
					);
				}
				case 'ContentfulVideoEmbed': {
					const target = node.data.target;
					return <VideoEmbed url={target?.url} />;
				}
				default: {
					return children;
				}
			}
		},
	},
};

const ContentfulRenderer = ({ richText }: { richText: { raw: string; references: any[] } }) => {
	const parsed = JSON.parse(richText.raw);
	const withHeadingIds = {
		...parsed,
		content: addHeadingId(parsed.content),
	};
	const stringified = JSON.stringify(withHeadingIds);

	return (
		<Fragment>
			{renderRichText(
				{
					...richText,
					raw: stringified,
				},
				options,
			)}
		</Fragment>
	);
};

const ContentRenderer = ({ children, richText }: ContentRendererProps) => {
	return (
		<ErrorBoundary fallback={<ContentRenderingError />}>
			<ContentfulRenderer richText={richText} />
			{children}
		</ErrorBoundary>
	);
};

interface ContentRendererProps {
	richText: any;
	children?: React.ReactNode;
}

export default ContentRenderer;
