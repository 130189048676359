import React from 'react';

import Anchor from '@atlaskit/primitives/anchor';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import DownloadIcon from '@atlaskit/icon/glyph/download';
import VisuallyHidden from '@atlaskit/visually-hidden';

import ThemedImg from '../themed-img';

const cardStyles = xcss({
	gridColumn: 'span 12',
	[media.above.xs]: {
		gridColumn: 'span 6',
	},
	textDecoration: 'none',
	display: 'block',
	backgroundColor: 'color.background.neutral',

	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
		textDecoration: 'none',
	},
	':active': {
		backgroundColor: 'color.background.neutral.pressed',
	},
});

const headerStyles = xcss({
	paddingBlock: 'space.250',
	paddingInline: 'space.200',
	backgroundColor: 'color.background.neutral',
	overflow: 'hidden',
	color: 'color.text',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	lineHeight: '20px',
	borderStartStartRadius: 'border.radius',
	borderStartEndRadius: 'border.radius',
});

const contentStyles = xcss({
	padding: 'space.200',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	transition: 'background-color 0.2s ease-in',
	borderEndStartRadius: 'border.radius',
	borderEndEndRadius: 'border.radius',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		maxHeight: '70px',
		maxWidth: '75%',
	},

	[media.above.xs]: {
		height: '11rem',

		// @ts-expect-error
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		img: {
			maxHeight: '100%',
			maxWidth: '100%',
		},
	},
});

interface AssetCardProps {
	title: string;
	link: string;
	fileSize: number;
	thumbnail: { light: string; dark: string };
}

const AssetCard = ({ title, link, fileSize, thumbnail }: AssetCardProps) => {
	const KB = fileSize / 1024;
	const titleWithFileSize = `${title} (${(KB > 1000 ? KB / 1024 : KB).toFixed(2)}KB)`;

	return (
		<Anchor xcss={cardStyles} href={link}>
			<VisuallyHidden>Download {titleWithFileSize}</VisuallyHidden>
			<Box xcss={headerStyles}>
				<Text maxLines={1}>{titleWithFileSize}</Text>
				<DownloadIcon label="" size="medium" />
			</Box>
			<Box xcss={contentStyles}>
				<ThemedImg
					src={{
						light: thumbnail.light,
						dark: thumbnail.dark,
					}}
					alt=""
				/>
			</Box>
		</Anchor>
	);
};

export default AssetCard;
