/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useStaticQuery, graphql } from 'gatsby';
import EmptyState from '@atlaskit/empty-state';
import ContentRenderer, {
	ContentRendererWrapper,
} from '../../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/content/content-renderer';

export default ({ fileName }: { fileName: string }) => {
	/**
	 * Not ideal, since we're querying all mdx content from graphql.
	 * This query will take a long time to return and should be optimized.
	 * One idea could be to add an additional property to the graphql schema
	 * in the form of "isInternalDoc" (or similar) to allow us to narrow this
	 * query down.
	 */
	const data = useStaticQuery(graphql`
		query {
			allMdx(filter: { fileAbsolutePath: { regex: "/design-system-docs/src/mdx/" } }) {
				nodes {
					slug
					body
				}
			}
		}
	`);

	const mdx = data.allMdx.nodes.find((mdx: any) => mdx.slug === fileName);

	return mdx ? (
		<ContentRendererWrapper>
			<ContentRenderer body={mdx.body} id={fileName} />
		</ContentRendererWrapper>
	) : (
		// @ts-expect-error this should probably have a header?
		<EmptyState
			description={`Cannot find the markdown file - "${fileName}.md" in the folder "services/design-system-docs/src/mdx"`}
		/>
	);
};
