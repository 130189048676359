import React from 'react';
import { graphql } from 'gatsby';

import PageWithLocalNav from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/templates/page-with-local-nav';
import { ContextualAnalyticsData, SCREEN, FireScreenAnalytics } from '@atlassian/analytics-bridge';
import ContentRenderer from '../components/content-renderer';
import { ContentRenderingError, HeadingContextProvider } from '@af/design-system-docs-ui';
import { normalizeSourceName } from '../utils/analytics';
import titleify from '../utils/titleify';
import { EMPTY_TITLE_PLACEHOLDER } from '../utils/constants';

const GuidelinePage: React.ComponentType<{ data: Record<string, any> }> = ({ data }) => {
	const guideline = data?.contentfulGuideline;

	if (guideline) {
		const title = guideline.title || EMPTY_TITLE_PLACEHOLDER;
		const parentPage = guideline?.contentfulparent
			? titleify(guideline.contentfulparent.slug)
			: title;
		const parentPageTitle = parentPage === title ? null : parentPage;

		const sourceName = normalizeSourceName(guideline.category, guideline.title);
		const categoryName = normalizeSourceName(guideline.category);

		return (
			<ContextualAnalyticsData
				sourceType={SCREEN}
				sourceName="content"
				attributes={{ name: sourceName, context: categoryName }}
			>
				<HeadingContextProvider>
					<PageWithLocalNav
						parentPageTitle={parentPageTitle}
						title={title}
						description={guideline.description?.description}
						guidelineCategory={guideline.category}
					>
						{guideline?.bodyText?.raw && (
							<ContentRenderer richText={guideline.bodyText}>
								<FireScreenAnalytics />
							</ContentRenderer>
						)}
					</PageWithLocalNav>
				</HeadingContextProvider>
			</ContextualAnalyticsData>
		);
	}

	return <ContentRenderingError />;
};

export const query = graphql`
	query ($slug: String!) {
		contentfulGuideline(slug: { eq: $slug }) {
			contentful_id
			title
			contentfulparent {
				slug
			}
			category
			description {
				description
			}
			bodyText {
				raw
				references {
					... on ContentfulAsset {
						__typename
						contentful_id
						file {
							url
						}
						description
					}
					... on ContentfulGuideline {
						__typename
						contentful_id
						slug
						category
						contentfulparent {
							slug
						}
					}
					... on ContentfulDosAndDonts {
						__typename
						contentful_id
						text
						type
						image {
							file {
								url
							}
						}
					}
					... on ContentfulColorCard {
						__typename
						contentful_id
						name
						hexCode
					}
					... on ContentfulSectionMessage {
						__typename
						contentful_id
						title
						appearance
						message {
							raw
						}
						action1 {
							link
							text
						}
						action2 {
							link
							text
						}
					}
					... on ContentfulAssetCard {
						__typename
						contentful_id
						title
						asset {
							file {
								details {
									size
								}
								url
							}
						}
						thumbnail {
							file {
								url
							}
						}
						thumbnailDark {
							file {
								url
							}
						}
					}
					... on ContentfulMdxFile {
						__typename
						contentful_id
						fileName
					}
					... on ContentfulVideoEmbed {
						__typename
						contentful_id
						url
					}
					... on ContentfulMarkdown {
						__typename
						contentful_id
						childContentfulMarkdownTextTextNode {
							text
						}
					}
					... on ContentfulThemedImage {
						__typename
						contentful_id
						title
						light {
							description
							file {
								details {
									image {
										height
										width
									}
								}
								url
							}
						}
						dark {
							description
							file {
								details {
									image {
										height
										width
									}
								}
								url
							}
						}
					}
				}
			}
		}
	}
`;

export default GuidelinePage;
