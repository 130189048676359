import { BLOCKS } from '@contentful/rich-text-types';

const slugify = require('github-slugger').slug;

export interface Content {
	data: {};
	marks: [];
	value: string;
	nodeType: string;
}

export interface Node {
	content: Content[];
	nodeType: string;
	data: {};
	headingId?: number;
}

export const getHeadingId = (node: Node) => {
	const { headingId = 1, content } = node;

	// Appends with ID in case there are multiple headings with the same content.
	// The first heading is left untouched to prevent this being added when unnecessary.
	if (content[0].value) {
		const id = slugify(content[0].value);

		return headingId > 1 ? `${id}-${headingId}` : id;
	}
};

export const addHeadingId = (content: Node[]) => {
	const uniqueHeadings: Record<string, number> = {};
	const updatedContent = content.map((node) => {
		const { nodeType, content } = node;
		const headingNodeType = nodeType === BLOCKS.HEADING_2 || nodeType === BLOCKS.HEADING_3;

		if (headingNodeType && content[0].value) {
			const slug = slugify(content[0].value);

			uniqueHeadings[slug] ? uniqueHeadings[slug]++ : (uniqueHeadings[slug] = 1);

			return {
				...node,
				headingId: uniqueHeadings[slug],
			};
		}
		return node;
	});

	return updatedContent;
};
